import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

import InformationLayout from "../../layouts/InformationLayout.jsx";
import indexData from "./index.json";
import Downloads from "../../components/_ui/Downloads.jsx";
export const _frontmatter = {
  "title": "Pergola Planning - Preparation"
};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const layoutProps = {
  _frontmatter
};

const MDXLayout = ({
  children,
  pagesList
}) => <InformationLayout pagesList={indexData.pageIndex}>
    {children}
  </InformationLayout>;

export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <h1>{`Preparation`}</h1>
    <h2>{`Choosing your location:`}</h2>
    <p>{`Ideally, your ‘lean-to’ pergola will be sited on an outside wall of your home that allows access to your garden, drive or patio via a set of French windows or outer-door. This will increase the effective living space that you already have inside the home without you having to cross any area, open to the elements, so avoiding any wet, damp or muddy conditions.`}</p>
    <p>{`A timber pergola is one of the most attractive ways of extending your living area - especially after climbing plants and/or vines have covered the structure. When designed and built your pergola can be made to make the most of winter sun while offering protection from the hot summer rays. Don’t pick a spot that is already in shade because, if you build here, you’ll end up with a place where nobody wants to sit and you will not get the best growing results from your plants either.`}</p>
    <p>{`Best of all, as your pergola is only a simple wooden structure and is delivered complete with all the necessary fixings, you don’t need to be a genius to build it!`}</p>
    <h2>{`Before you start:`}</h2>
    <ol>
      <li parentName="ol">{`Clear all vegetation from the location that you want the pergola situated`}</li>
      <li parentName="ol">{`Level the site if required`}</li>
      <li parentName="ol">{`Make sure there is sufficient space for the fitting of the ledger board on the exterior wall of your home/building`}</li>
      <li parentName="ol">{`Measure the area of ground where the pergola is to be situated. Identify where the posts will be and mark their exact position`}</li>
      <li parentName="ol">{`Ensure that they are arranged so that they are square with each other`}</li>
      <li parentName="ol">{`Check that there are no underlying utilities or cables (such as water, drainage, gas or oil pipes or electricity cables). Read our Disclaimer section for further advice.`}</li>
      <li parentName="ol">{`Make sure that any windows or doors will not be impeded by the pergola.`}</li>
    </ol>
    <h2>{`Downloads & Support`}</h2>
    <Downloads files={[{
      filename: "Pergola assembly guide",
      path: "/downloads/ASSEMBLY.pdf"
    }, {
      filename: "Pergola wood treatment information",
      path: "/downloads/EN-Wolmanit_CX_enduser_022010.pdf"
    }]} mdxType="Downloads" />

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      